// Base16 - Material Darker
$base00-darker: "#212121";
$base01-darker: "#303030";
$base02-darker: "#353535";
$base03-darker: "#4A4A4A";
$base04-darker: "#B2CCD6";
$base05-darker: "#EEFFFF";
$base06-darker: "#EEFFFF";
$base07-darker: "#FFFFFF";
$base08-darker: "#F07178";
$base09-darker: "#F78C6C";
$base0A-darker: "#FFCB6B";
$base0B-darker: "#C3E88D";
$base0C-darker: "#89DDFF";
$base0D-darker: "#82AAFF";
$base0E-darker: "#C792EA";
$base0F-darker: "#FF5370";

// Base16 - Material Lighter
$base00-lighter: "#FAFAFA";
$base01-lighter: "#E7EAEC";
$base02-lighter: "#CCEAE7";
$base03-lighter: "#CCD7DA";
$base04-lighter: "#8796B0";
$base05-lighter: "#80CBC4";
$base06-lighter: "#80CBC4";
$base07-lighter: "#FFFFFF";
$base08-lighter: "#FF5370";
$base09-lighter: "#F76D47";
$base0A-lighter: "#FFB62C";
$base0B-lighter: "#91B859";
$base0C-lighter: "#39ADB5";
$base0D-lighter: "#6182B8";
$base0E-lighter: "#7C4DFF";
$base0F-lighter: "#E53935";

// Root for css variables
:root {
    // Code variables
    --code-text-red: #{$base08-darker};
    --code-text-orange: #{$base09-darker};
    --code-text-yellow: #{$base0A-darker};
    --code-text-green: #{$base0B-darker};
    --code-text-teal: #{$base0C-darker};
    --code-text-blue: #{$base0D-darker};
    --code-text-purple: #{$base0E-darker};

    @include lightTheme() {
        --code-bg: #{$base00-lighter};
        --code-text: #{$base00-darker};
        --code-text-red: #{$base08-lighter};
        --code-text-orange: #{$base09-lighter};
        --code-text-yellow: #{$base0A-lighter};
        --code-text-green: #{$base0B-lighter};
        --code-text-teal: #{$base0C-lighter};
        --code-text-blue: #{$base0D-lighter};
        --code-text-purple: #{$base0E-lighter};
    }

    // Body variables
    --body-bg: #{$base04-lighter};

    @include lightTheme() {
        --body-bg: #{$base04-darker};
    }

    // Window variables
    --window-bg: #{$base00-darker};
    --window-text: #{$base00-lighter};
    --window-control: #{$base03-darker};
    --window-control-green: #{$base0B-lighter};
    --window-control-yellow: #{$base0A-lighter};
    --window-control-red: #{$base08-lighter};

    @include lightTheme() {
        --window-bg: #{$base00-lighter};
        --window-text: #{$base00-darker};
    }

}

// SIZING
$window-padding-top: calculateRem(48px);
$window-padding-bottom: calculateRem(18px);
$window-padding-sides: calculateRem(18px);
$window-control-bar-height: $window-padding-top;
$window-control-size: calculateRem(12px);