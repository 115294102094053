html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: calculateRem(24px) calculateRem(16px);
    box-sizing: border-box;
    background-color: v(body-bg);

    > * {
        margin-left: auto;
        margin-right: auto;
    }

    > :first-child {
        margin-top: auto;
    }

    > :last-child {
        margin-bottom: auto;
    }
}