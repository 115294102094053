body {
    font-family: 'Hack', monospace;
    line-height: percentage(4 / 3);
}

a {
    outline: none;
    text-decoration: none;
    color: inherit;
    font-weight: inherit;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}