.code {
    &__function {
        color: v(code-text-purple);

        &:after {
            content: ";";
            display: inline;
            color: v(code-text-teal);
        }
    }

    &__function-name {
        color: v(window-text);
    }

    &__bracket {
        // Parentheses by default - ()

        &:before,
        &:after {
            display: inline;
            color: v(code-text-yellow);
        }

        &:before {
            content: "(";
        }

        &:after {
            content: ")";
        }

        &--square {
            &:before {
                content: "[";
            }
    
            &:after {
                content: "]";
            }
        }

        &--angle {
            &:before {
                content: "<";
            }
    
            &:after {
                content: ">";
            }
        }

        &--curly {
            &:before {
                content: "{";
            }
    
            &:after {
                content: "}";
            }
        }
    }

    &__integer {
        color: v(code-text-orange);
    }

    &__string {
        color: v(code-text-green);

        &:before,
        &:after {
            content: "\"";
            display: inline;
            color: v(code-text-teal);
        }
    }

    &__property {
        padding-left: 4ch;
        user-select: none;
        color: v(window-text);

        &:after {
            content: ":";
            display: inline;
        }
    }
}