.window {
    position: relative;
    padding: $window-padding-top $window-padding-sides $window-padding-bottom;
    border-radius: calculateRem(6px);
box-shadow:rgba(#000, 0.55) 0 calculateRem(20px) calculateRem(100px);
    background-color: v(window-bg);
    color: v(window-text);

    &__controls {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        height: $window-control-bar-height;
        padding: 0 $window-padding-sides;
    }

    &__control {
        height: $window-control-size;
        width: $window-control-size;
        margin: auto 0;
        border-radius: 50%;
        background-color: v(window-control);

        &:nth-child(n + 2) {
            margin-left: calculateRem(8px);
        }

        &--green {
            background-color: v(window-control-green);
        }

        &--yellow {
            background-color: v(window-control-yellow);
        }

        &--red {
            background-color: v(window-control-red);
        }
    }
}