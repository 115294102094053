:root {
  --code-text-red: #F07178;
  --code-text-orange: #F78C6C;
  --code-text-yellow: #FFCB6B;
  --code-text-green: #C3E88D;
  --code-text-teal: #89DDFF;
  --code-text-blue: #82AAFF;
  --code-text-purple: #C792EA;
  --body-bg: #8796B0;
  --window-bg: #212121;
  --window-text: #FAFAFA;
  --window-control: #4A4A4A;
  --window-control-green: #91B859;
  --window-control-yellow: #FFB62C;
  --window-control-red: #FF5370; }
  @media (prefers-color-scheme: light) {
    :root {
      --code-bg: #FAFAFA;
      --code-text: #212121;
      --code-text-red: #FF5370;
      --code-text-orange: #F76D47;
      --code-text-yellow: #FFB62C;
      --code-text-green: #91B859;
      --code-text-teal: #39ADB5;
      --code-text-blue: #6182B8;
      --code-text-purple: #7C4DFF; } }
  @media (prefers-color-scheme: light) {
    :root {
      --body-bg: #B2CCD6; } }
  @media (prefers-color-scheme: light) {
    :root {
      --window-bg: #FAFAFA;
      --window-text: #212121; } }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }
  blockquote:before, blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.code__function {
  color: var(--code-text-purple); }
  .code__function:after {
    content: ";";
    display: inline;
    color: var(--code-text-teal); }

.code__function-name {
  color: var(--window-text); }

.code__bracket:before, .code__bracket:after {
  display: inline;
  color: var(--code-text-yellow); }

.code__bracket:before {
  content: "("; }

.code__bracket:after {
  content: ")"; }

.code__bracket--square:before {
  content: "["; }

.code__bracket--square:after {
  content: "]"; }

.code__bracket--angle:before {
  content: "<"; }

.code__bracket--angle:after {
  content: ">"; }

.code__bracket--curly:before {
  content: "{"; }

.code__bracket--curly:after {
  content: "}"; }

.code__integer {
  color: var(--code-text-orange); }

.code__string {
  color: var(--code-text-green); }
  .code__string:before, .code__string:after {
    content: "\"";
    display: inline;
    color: var(--code-text-teal); }

.code__property {
  padding-left: 4ch;
  user-select: none;
  color: var(--window-text); }
  .code__property:after {
    content: ":";
    display: inline; }

/*!
 *  Hack typeface https://github.com/source-foundry/Hack
 *  License: https://github.com/source-foundry/Hack/blob/master/LICENSE.md
 */
/* FONT PATHS
 * -------------------------- */
@font-face {
  font-family: "Hack";
  src: url("assets/font/hack-regular-subset.woff2?sha=3114f1256") format("woff2"), url("assets/font/hack-regular-subset.woff?sha=3114f1256") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Hack";
  src: url("assets/font/hack-bold-subset.woff2?sha=3114f1256") format("woff2"), url("assets/font/hack-bold-subset.woff?sha=3114f1256") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Hack";
  src: url("assets/font/hack-italic-subset.woff2?sha=3114f1256") format("woff2"), url("assets/font/hack-italic-webfont.woff?sha=3114f1256") format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Hack";
  src: url("assets/font/hack-bolditalic-subset.woff2?sha=3114f1256") format("woff2"), url("assets/font/hack-bolditalic-subset.woff?sha=3114f1256") format("woff");
  font-weight: 700;
  font-style: italic; }

html {
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 1.5rem 1rem;
  box-sizing: border-box;
  background-color: var(--body-bg); }
  body > * {
    margin-left: auto;
    margin-right: auto; }
  body > :first-child {
    margin-top: auto; }
  body > :last-child {
    margin-bottom: auto; }

body {
  font-family: 'Hack', monospace;
  line-height: 133.33333%; }

a {
  outline: none;
  text-decoration: none;
  color: inherit;
  font-weight: inherit; }
  a:hover, a:focus {
    text-decoration: underline; }

.window {
  position: relative;
  padding: 3rem 1.125rem 1.125rem;
  border-radius: 0.375rem;
  box-shadow: rgba(0, 0, 0, 0.55) 0 1.25rem 6.25rem;
  background-color: var(--window-bg);
  color: var(--window-text); }
  .window__controls {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    height: 3rem;
    padding: 0 1.125rem; }
  .window__control {
    height: 0.75rem;
    width: 0.75rem;
    margin: auto 0;
    border-radius: 50%;
    background-color: var(--window-control); }
    .window__control:nth-child(n + 2) {
      margin-left: 0.5rem; }
    .window__control--green {
      background-color: var(--window-control-green); }
    .window__control--yellow {
      background-color: var(--window-control-yellow); }
    .window__control--red {
      background-color: var(--window-control-red); }
